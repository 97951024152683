.logo {
  width: 75px;
  height: 96px;
  margin-left: 30px;
  display: block;
}

.contact {
  margin-left: 30px;
}

.homepage {
  display: flex;
  justify-content: center;
}

.Box-Scores {
  display: flex;
  justify-content: center;
  margin: 25px 0px !important;
}

.Box-Scores:not(.content-table) {
  margin: 25px !important;
}

.logoin {
  display: flex;
  justify-content: space-between;
}

.teamlink {
  color: #000;
  text-decoration: underline;
}

.center {
  margin: auto;
}

.content-table {
  display: inline-table;
  border-collapse: collapse;
  margin: 25px;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 0.9em;
  min-width: auto;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #4C001E;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
  text-align: center;
}

tbody > tr:hover {
  background-color: #dfc0cc !important;
}

.content-table tbody tr:nth-of-type(even):not(.week)  {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #4C001E;
}

.content-table.border-right tr {
  border-right: 2px solid #4C001E;
}

.content-table.border-left tr {
  border-left: 2px solid #4C001E;
}

.week {
  background-color: #dfc0cc;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fcfcfc;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 75px;
  background-color: #4C001E;
  position: relative;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #ffffff;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .homepage {
    display: block;
  }

  .Box-Scores {
    display: block;
    justify-content: flex-start;
    margin: 0px !important;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #4C001E;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }

  .content-table th,
  .content-table td {
    padding: 3px 4px;
  }

  .content-table {
    overflow-x: auto;
    display: block;
  }
}

button {
  border-color: #4c001e;
  border-style: solid;
  padding: 0.3em 1em;
  margin: 0px 2px;
  border-radius: 100px;
  letter-spacing: 1px;
  background-color: #4c001e !important;
  color: #ffffff;
}

button:hover {
  background-color: #64283f !important;
}

input[type=text] {
  border:2px solid #aaa;
  border-radius:4px;
  margin:8px 0;
  outline:none;
  padding:8px;
  box-sizing:border-box;
  transition:.3s;
}

input[type=text]:focus {
  border-color:dodgerBlue;
  box-shadow:0 0 8px 0 dodgerBlue;
}

.submitForm {
  margin: 0px 25px 10px 25px;
  display: block;
}

.editScheduleInput {
  width: 70px;
  color: black;
}

.editStandingInput {
  width: 35px;
}